import oRequest from '@/libs/request.js';
const URL = {
}
export default {
	saveData(data = {},url='') {
		return new Promise((resolve, recject) => {
			oRequest.getAjax('admin/' + url + '/saveData', data).then(res => {
				resolve(res);
			})
		})
	},
	getInfo(data = {},url='') {
		return new Promise((resolve, recject) => {
			oRequest.getAjax('admin/' + url + '/getInfo', data).then(res => {
				resolve(res);
			})
		})
	},
	initPage(data = {},url='') {
		return new Promise((resolve, recject) => {
			console.log(this.$route);
			oRequest.getAjax('admin/' + url + '/initPage', data).then(res => {
				resolve(res);
			})
		})
	},
	getList(data = {},url='') {
		return new Promise((resolve, recject) => {
			oRequest.getAjax('admin/' + url + '/getList', data).then(res => {
				resolve(res);
			})
		})
	},
	request(url='', data = {}) {
		return new Promise((resolve, recject) => {
			oRequest.getAjax(url, data).then(res => {
				resolve(res);
			})
		})
	},
	getDown(url='', data = {}) {
		oRequest.getAjaxDown(url, data)
	},
}

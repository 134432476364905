<template>
	<div>
		<el-card>
			<el-tabs v-model="nActiveTabs">
			    <el-tab-pane label="个人转化率" name="1">
						<el-card class="box-card">
							<el-tabs v-model="nActiveZhuanhua">
								<template style="padding-bottom: 10px;" v-for="(item,key) in arrCustomer">
								<el-tab-pane :label="item.name" :name="key">
									<template style="padding-bottom: 10px;" v-for="(items,keys) in item.data">
										<div style="margin-top: 10px;"></div>
										<el-card>
											<div slot="header" class="clearfix">
											    <span>{{items.name}}</span>
											  </div>
											
											<el-row :gutter="12">
												<el-col :span="8">
													<el-card shadow="always">
														线索总数：{{items.clues_total}}
													</el-card>
												</el-col>
												<el-col :span="8">
													<el-card shadow="always">
														线索客户：{{items.customer}}
													</el-card>
												</el-col>
												<el-col :span="8">
													<el-card shadow="always">
														转化数量：{{items.success}}
													</el-card>
												</el-col>
											</el-row>
											<div style="padding: 10px;">
												<el-progress :text-inside="true" :stroke-width="26" :percentage="items.proportion" :status="items.status"></el-progress>
											</div>
										</el-card>
										</template>
								</el-tab-pane>
							</template>
						</el-tabs>
					</el-card>
					
				</el-tab-pane>
			    <el-tab-pane label="活动转化率" name="2">
					<div style="padding-bottom: 10px;" v-for="item in arrGrop">
						<el-card class="box-card">
							<div slot="header" class="clearfix">
								<span>{{item.name}}数据统计</span>
							</div>
							<el-row :gutter="12">
								<el-col :span="8">
									<el-card shadow="always">
										线索总数：{{item.clues_total}}
									</el-card>
								</el-col>
								<el-col :span="8">
									<el-card shadow="always">
										客户数量：{{item.customer}}
									</el-card>
								</el-col>
								<el-col :span="8">
									<el-card shadow="always">
										转化数量：{{item.success}}
									</el-card>
								</el-col>
							</el-row>
							<div style="padding: 10px;">
								<el-progress :text-inside="true" :stroke-width="26" :percentage="item.proportion" :status="item.status"></el-progress>
							</div>
						</el-card>
					</div>
				</el-tab-pane>
			    <el-tab-pane label="账户转化率" name="3">
					<el-card class="box-card">
						<el-tabs v-model="nActiveZhuanhua">
							<template  v-for="(item,key) in arrPlatform">
								<el-tab-pane :label="item.name" :name="key">
									<div style="padding-bottom: 10px;" v-for="(items,keys) in arrPlatform[key].data">
										<el-card>
											<div slot="header" class="clearfix">
												<span>{{items.name}}</span>
											</div>
											<div style="margin-top: 10px;"></div>
											<el-row :gutter="30">
												<el-col :span="6">
													<el-card shadow="always">
														线索总数：{{items.clues_total}}
													</el-card>
												</el-col>
												<el-col :span="6">
													<el-card shadow="always">
														今日新增：{{items.clues_today}}
													</el-card>
												</el-col>
												
												<el-col :span="6">
													<el-card shadow="always">
														客户数量：{{items.customer}}
													</el-card>
												</el-col>
												<el-col :span="6">
													<el-card shadow="always">
														转化数量：{{items.success}}
													</el-card>
												</el-col>
											</el-row>
											<div style="padding: 10px;">
												<el-progress :text-inside="true" :stroke-width="26" :percentage="items.proportion" :status="items.status"></el-progress>
											</div>
										</el-card>
									</div>
								</el-tab-pane>
							</template>
						</el-tabs>
					</el-card>
				</el-tab-pane>
			    <el-tab-pane label="排行榜" name="4">
					<el-card class="box-card">
						<el-tabs v-model="nActiveZhuanhua">
							<template  v-for="(item,key) in arrRank">
								<el-tab-pane :label="item.name" :name="key">
									<div style="padding-bottom: 10px;" v-for="(items,keys) in arrRank[key].data">
										<div>
											<span style="display: flex;">
												<div style="width: 280px;">{{keys+1}}.{{items.name}}</div>
												<el-progress :text-inside="true" :stroke-width="26" :percentage="items.proportion" :status="items.status"></el-progress>
											</span>
										</div>
									</div>
								</el-tab-pane>
							</template>
						</el-tabs>
					</el-card>
				</el-tab-pane>
			  </el-tabs>
		</el-card>
	</div>
</template>

<script>
	import oJs from "@/js/config.js";
	export default {
		data() {
			return {
				arrCustomer: {}, // 个人转化率
				arrGrop:[], // 小组转化率
				arrPlatform:[], // 账户转化率
				nActiveTabs:"1", // 默认选中的选项卡
				arrRank:[],
				nActiveZhuanhua: "0", // 默认选中的账户
			}
		},
		created() {
			this.initData()
		},
		methods: {
			/**
			 * 初始化数据
			 */
			initData() {
				oJs.request('admin/main/index').then(res => {
					this.arrCustomer = res.customer
					for (let i=0; i<this.arrCustomer.length; i++) {
						for (let x=0; x<this.arrCustomer[i].data.length; x++) {
							if (this.arrCustomer[i].data[x].proportion <= 50){
								this.arrCustomer[i].data[x].status = 'exception'
							}
							else if (this.arrCustomer[i].data[x].proportion < 55){
								this.arrCustomer[i].data[x].status = 'warning'
							}
							else if (this.arrCustomer[i].data[x].proportion < 60){
								this.arrCustomer[i].data[x].status = ''
							}
							else{
								this.arrCustomer[i].data[x].status = 'success'
							}
						}
						
					}
					
					this.arrGrop = res.group
					for (let i=0; i<this.arrGrop.length; i++) {
						if (this.arrGrop[i].proportion <= 50){
							this.arrGrop[i].status = 'exception'
						}
						else if (this.arrGrop[i].proportion < 55){
							this.arrGrop[i].status = 'warning'
						}
						else if (this.arrGrop[i].proportion < 60){
							this.arrGrop[i].status = ''
						}
						else{
							this.arrGrop[i].status = 'success'
						}
					}
					
					this.arrPlatform = res.platform
					for (let i=0; i<this.arrPlatform.length; i++) {
						for (let x=0; x<this.arrPlatform[i].data.length; x++) {
							if (this.arrPlatform[i].data[x].proportion <= 50){
								this.arrPlatform[i].data[x].status = 'exception'
							}
							else if (this.arrPlatform[i].data[x].proportion < 55){
								this.arrPlatform[i].data[x].status = 'warning'
							}
							else if (this.arrPlatform[i].data[x].proportion < 60){
								this.arrPlatform[i].data[x].status = ''
							}
							else{
								this.arrPlatform[i].data[x].status = 'success'
							}
						}
					}
					
					this.arrRank = res.rank
					for (let i=0; i<this.arrRank.length; i++) {
						for (let x=0; x<this.arrRank[i].data.length; x++) {
							if (this.arrRank[i].data[x].proportion <= 50){
								this.arrRank[i].data[x].status = 'exception'
							}
							else if (this.arrRank[i].data[x].proportion < 55){
								this.arrRank[i].data[x].status = 'warning'
							}
							else if (this.arrRank[i].data[x].proportion < 60){
								this.arrRank[i].data[x].status = ''
							}
							else{
								this.arrRank[i].data[x].status = 'success'
							}
						}
						
					}
					
				})
			},
			
			// 修改选项卡
			changeTabs(){
				
			}
		}
	}
</script>

<style>
	.el-progress{
		width: 100%;
	}
</style>
